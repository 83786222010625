import {
  faBell as fullbell,
  faGamepad
} from "@fortawesome/free-solid-svg-icons";
import "./CombineMenu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { Service_GL } from "utils/CommonFunc";
import { Link, useLocation } from "react-router-dom";
import { VFUN_IMAGE_DNS } from "utils/config/Config";
import { useRecoilState, useRecoilValue } from "recoil";
import { t } from "i18next";
import axios from "axios";
import { useEffect } from "react";
import { useQuery } from "react-query";
import {
  headersState,
  headersType,
  isLoginStateSelector
} from "atoms/userInfoAtom";
import { ILauncherData, launcherStartApi } from "api/launcherApi";
import {
  dropBoxToggleState,
  isAllReadState,
  isNewNotiState
} from "./recoil/recoil";
import { getNewNoti } from "./api/api";
import SideMenu from "./components/SideMenu";
import Language from "./components/Language";
import Profile from "./components/Profile";
import Notification from "./components/Notification";
import UserProfileImg from "../UserProfileImg";
import { CMservice_code } from "./utils/serviceCode";
import { isSafari } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

const onClickLauncherBtn = async (
  service_code: string,
  headers: headersType
) => {
  // if (isSafari) {
  //   alert("window에서 지원합니다.");
  //   return;
  // }

  const failureTimeout = setTimeout(() => {
    // 실패시 코드

    alert(t("need_install_launcher"));
    window.location.replace(
      `${process.env.REACT_APP_PLATFORM_DNS}/game/download`
    );
  }, 1000);

  window.addEventListener("blur", () => {
    clearTimeout(failureTimeout);
  });

  const link = document.createElement("a");

  try {
    let res = await axios.post(
      launcherStartApi,
      { service_code: service_code },
      {
        headers: { ...headers }
      }
    );
    const data: ILauncherData = res.data.data;
    if (res.data.result === 1) {
      link.href = data.game_run_url;
    } else if (res.data.result === -97) {
      link.href = data.protocol;
    } else {
      console.log("왕실패");
    }
  } catch (error) {
    console.log("launcher", error);
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function CombineMenu() {
  const [dropBoxToggle, setDropBoxToggle] = useRecoilState(dropBoxToggleState);
  const [isNewNoti, setIsNewNoti] = useRecoilState(isNewNotiState);
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const headers = useRecoilValue(headersState);
  const isAllRead = useRecoilValue(isAllReadState);
  const location = useLocation();

  const width360 = useMediaQuery({
    query: "screen and (max-width: 360px)"
  });

  const { isLoading, data } = useQuery(
    ["new-noti", isAllRead.is],
    () => getNewNoti(headers),
    {
      enabled: Service_GL && isLoginState.isLogIn,
      onSuccess: (data) => {
        if (data?.result === 1) {
          setIsNewNoti({
            vfun: data.data.non_read[0].count !== 0,
            lounge: data.data.non_read[1].count !== 0,
            ground: data.data.non_read[2].count !== 0
          });
        }
      }
    }
  );

  const isNew = isNewNoti.ground || isNewNoti.lounge || isNewNoti.vfun;
  const logo = `${VFUN_IMAGE_DNS}/${
    width360
      ? "web/vfun/images/logo/vfun-logo.png"
      : "web/vfun-ko/images/logo/logo-vfun.png"
  }`;

  const onClickBellBtn = () => {
    if (dropBoxToggle.profile || dropBoxToggle.lang) {
      setDropBoxToggle({ profile: false, bell: true, lang: false });
    } else {
      setDropBoxToggle((prev) => ({ ...prev, bell: !prev.bell }));
    }
  };

  const onClickProfileBtn = () => {
    if (dropBoxToggle.bell || dropBoxToggle.lang) {
      setDropBoxToggle({ profile: true, bell: false, lang: false });
    } else {
      setDropBoxToggle((prev) => ({ ...prev, profile: !prev.profile }));
    }
  };

  useEffect(() => {
    setDropBoxToggle({ profile: false, bell: false, lang: false });
  }, [location.pathname]);

  return (
    <div id="combineMenu" className="clearfix">
      <SideMenu />

      <div className="logo-area">
        <Link to={process.env.REACT_APP_PLATFORM_DNS as string}>
          <img src={logo} alt="logo" />
          <span>{Service_GL ? "GL" : "KR"}</span>
        </Link>
      </div>

      <ul className="right-area">
        {!isLoginState.isLauncher && (
          <li
            className="launcher-btn tab"
            onClick={() => onClickLauncherBtn(CMservice_code, headers)}
          >
            {t("run_launcher")}
            <FontAwesomeIcon icon={faGamepad} />
          </li>
        )}

        <li className="lang tab">
          <Language />
        </li>
        {isLoginState.isLogIn && (
          <li className="tab">
            {isNew && (
              <div className="dot-area">
                <span />
              </div>
            )}
            <FontAwesomeIcon
              icon={isNew ? fullbell : faBell}
              onClick={onClickBellBtn}
              bounce={isNew}
            />
            <Notification />
          </li>
        )}

        <li className="tab">
          <div onClick={onClickProfileBtn}>
            <UserProfileImg />
          </div>

          <Profile />
        </li>
      </ul>
    </div>
  );
}

export default CombineMenu;
