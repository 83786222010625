import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Notification.scss";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "axios";
import { headersState } from "atoms/userInfoAtom";
import {
  dropBoxToggleState,
  isAllReadState,
  isNewNotiState
} from "../recoil/recoil";
import { putAllRead } from "../api/api";
import NotificationList from "./NotificationList";
import { t } from "i18next";
import { QueryClient } from "react-query";

function Notification() {
  const [tabIdx, setTabIdx] = useState(1);
  const headers = useRecoilValue(headersState);
  const [dropBoxToggle, setDropBoxToggle] = useRecoilState(dropBoxToggleState);
  const setIsAllRead = useSetRecoilState(isAllReadState);
  const [lineStyle, setLineStyle] = useState({
    width: 0,
    left: 0
  });
  const isNewNoti = useRecoilValue(isNewNotiState);

  const queryClient = new QueryClient();

  const serviceList = [
    { title: "VFUN", idx: 1, isNew: isNewNoti.vfun },
    { title: "LOUNGE", idx: 2, isNew: isNewNoti.lounge },
    { title: "GROUND", idx: 3, isNew: isNewNoti.ground }
  ];

  useEffect(() => {
    if (!dropBoxToggle.bell) {
      if (isNewNoti.lounge) {
        setTabIdx(2);
      } else if (isNewNoti.ground) {
        setTabIdx(3);
      } else {
        setTabIdx(1);
      }
      setIsAllRead({ type: 0, is: false });
    }
  }, [dropBoxToggle.bell, isNewNoti]);

  useEffect(() => {
    if (!dropBoxToggle.bell) {
      setTabIdx(1);
      setIsAllRead({ type: 0, is: false });
    }
  }, [dropBoxToggle.bell]);

  useEffect(() => {
    const firstList = document.querySelector<HTMLLIElement>(
      `.services-tab > ul > li:nth-child(${tabIdx})`
    );

    if (firstList) {
      setLineStyle({
        width: firstList.clientWidth,
        left: firstList.offsetLeft
      });
    }
  }, [dropBoxToggle.bell]);

  const onClickTab = (e: React.MouseEvent<HTMLLIElement>, idx: number) => {
    setLineStyle({
      width: e.currentTarget.offsetWidth,
      left: e.currentTarget.offsetLeft
    });

    setTabIdx(idx);
  };

  const onClickAllRead = async () => {
    try {
      await axios
        .put(putAllRead(tabIdx), {}, { headers: { ...headers } })
        .then((res) => {
          setIsAllRead({ type: tabIdx, is: res.data.result === 1 });
          queryClient.invalidateQueries(["new-noti"]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="box drop notification"
      style={{ display: dropBoxToggle.bell ? "inline-block" : "none" }}
    >
      <div
        className="shadow"
        onClick={() => setDropBoxToggle((prev) => ({ ...prev, bell: false }))}
      />
      <div className="title-area">
        <p className="title">{t("announcement")}</p>
        <ul>
          {(isNewNoti.ground || isNewNoti.lounge || isNewNoti.vfun) && (
            <li onClick={onClickAllRead}>
              {t("read_all")}
              <FontAwesomeIcon icon={faCircleCheck} />
            </li>
          )}
          {/* <li>
            전체 보기
            <FontAwesomeIcon icon={faChevronRight} />
          </li> */}
        </ul>
      </div>
      <div className="services-tab">
        <ul>
          {serviceList.map((i, idx) => (
            <li
              className={tabIdx === idx + 1 ? "on" : "off"}
              key={idx}
              onClick={(e) => onClickTab(e, idx + 1)}
            >
              {i.title}
              {i.isNew && <span className="dot" />}
            </li>
          ))}
        </ul>
        <span
          className="under-bar"
          style={{ width: lineStyle.width, left: lineStyle.left }}
        />
      </div>
      <NotificationList tabIdx={tabIdx} />
    </div>
  );
}

export default Notification;
